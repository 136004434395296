import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';
import { injectGlobal } from 'react-emotion';
import sanitizeCss from '@jetshop/ui/Theme/sanitizeCss';
import ChannelContext from '@jetshop/core/components/ChannelContext';

import { createBreakpointHelpers } from '@jetshop/ui/utils/breakpoints';

/** 
    To define your own breakpoints, you can edit the breakpoint array below.
    The index of the breakpoint corresponds to the label. So, in this case, xs is at 20rem, sm is at 40rem, ect.
    You can add additional breakpoints or change the values of the ones that are currently there however it is important you keep the four existing labels as we use them for some components in @jetshop/ui 
. */

export const labels = ['xs', 'sm', 'md', 'lg', 'xl'];
const breakpoints = ['20rem', '40rem', '50rem', '80rem', '90rem'];

const { breakpointMap, above, below, between, only } = createBreakpointHelpers(
  labels,
  breakpoints
);

const fontSizes = [
  '12px',
  '14px',
  '16px',
  '20px',
  '24px',
  '32px',
  '48px',
  '64px',
  '72px',
];
const space = [
  '0px',
  '8px',
  '16px',
  '24px',
  '32px',
  '40px',
  '48px',
  '56px',
  '64px',
];
const fontWeights = {
  light: 300,
  regular: 400,
  semibold: 700,
};
let colors = {
  white: '#FFFFFF',
  black: '#000000',
  grey: '#878787',
  lightgrey: '#E8E8E8',
  tablegrey: '#F6F6F6',
  beige: '#f5f5dc',
  loadingBar: '#2f80ed',
  blue: '#2f80ed',
  red: '#FF0000',
  background: '#f7f7f7',
  darkgrey: '#333',
  mediumgrey: '#d5d5d5',
  alert: '#f95733',
  highlight: '#2f80ed',
  skiAccent: '#f8b133',
  skiAccentHover: 'd0942b',
  mtbAccent: '#5B702E',
  mtbAccentHover: '#4B601E',
};

const colorSchemeMtb = {
  primary: colors.mtbAccent,
  primaryHover: colors.mtbAccentHover,
};
const colorSchemeSki = {
  primary: colors.skiAccent,
  primaryHover: colors.skiAccentHover,
};

export const fonts = {
  primary: '"Roboto", sans-serif',
  secondary: '"futura-pt", sans-serif',
};

// Use https://meowni.ca/font-style-matcher/ to determine fallback styles that
// most closely match your fontFamily above
const fallbackStyles = `
  font-family: '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'Arial',
      'sans-serif';
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: -0.7px;
  word-spacing: -0.1px;
  font-weight: 400;
  visibility: visible;
`;

// This function will inject a global sanitize.css into your app.
// sanitize.css is a CSS library that provides consistent, cross-browser default styling of HTML elements alongside useful defaults.
// normalize.css and reset.css are also options if you so choose and can be imported from the same directory as sanitize css
sanitizeCss();

injectGlobal`
  html, body {
    height: 100%;
    font-family: ${fonts.primary};
  }
  html, body, #root {
    min-height: 100%;
  }
  #root {
    display: flex;
    flex-direction: column;
  }
  button {
	  cursor: pointer;
    padding: 0px;
    border: none;
    font: inherit;
    outline: none;
	}
  ol, ul, h4, h3, h2, h1 {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  p, dd {
    margin: 0;
    padding: 0;
  }
  input {
    -webkit-appearance: none;
    outline: none;
  }
  .ReactModal__Body--open {
    overflow: hidden;
  }
  .ReactModal__Overlay--after-open {
    overflow: scroll;
  }
  body {
    -webkit-font-smoothing: antialiased;
  }
  /* 
   * apply a natural box layout model to all elements, but allowing components
   * to change */
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: ${fonts.secondary};
    font-weight: 700;
    text-transform: uppercase;
  }
`;

export const theme = {
  labels,
  breakpoints,
  breakpointMap,
  above,
  below,
  between,
  only,
  fontSizes,
  space,
  fontWeights,
  colors,
  fonts,
};

export default (props) => {
  const { selectedChannel } = React.useContext(ChannelContext);

  if (selectedChannel && selectedChannel.id === 1) {
    theme.colorScheme = Object.assign(colors, colorSchemeSki);
  } else {
    theme.colorScheme = Object.assign(colors, colorSchemeMtb);
  }
  return <ThemeProvider {...{ ...props, theme }} />;
};
